import service from "@/service";
import axios from "@/libs/axios";
import { secretKey } from "@/auth/secretKey";

// Module Vuex akun
export default {
  namespaced: true,
  state: {
    currentNo: null,
    currentNoKolom: null,
    currentKolom: null,
    currentSoals: null,
    currentSoal: null,
    currentUjian: null,
    currentUserAnswer: null,
    hasilUjian: null,
    hasilRasionalisasi: null,
    detailUjian: null,
    hasil: null,
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data;
    },
    SET_BEARER(state, accessToken) {
      axios.defaults.headers.common["Author"] = `Bearer ${accessToken}`;
    },
    SET_CURRENT_KOLOM(state, data) {
      state.currentKolom = data;
    },
    SET_CURRENT_SOALS(state, data) {
      state.currentSoals = data;
    },
    SET_CURRENT_SOAL(state, data) {
      state.currentSoal = data;
    },
    SET_CURRENT_UJIAN(state, data) {
      state.currentUjian = data;
    },
    SET_CURRENT_USER_ANSWER(state, data) {
      state.currentUserAnswer = data;
    },
    SET_CURRENT_NO_KOLOM(state, data) {
      state.currentNoKolom = data;
    },
    SET_CURRENT_NO(state, data) {
      state.currentNo = data;
    },
    SET_CURRENT_HASIL_UJIAN(state, data) {
      state.hasilUjian = data;
    },
    SET_CURRENT_HASIL_RASIONALISASI(state, data) {
      state.hasilRasionalisasi = data;
    },
    SET_DETAIL_UJIAN(state, data) {
      state.detailUjian = data;
    },
    SET_HASIL(state, data) {
      state.hasil = data;
    },
  },
  actions: {
    async saveRumpunSiswa({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/input/rumpun-paket",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async saveJurusanSiswa({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/input/jurusan-paket",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async saveSekolahSiswa({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/input/jurusan-paket",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async mulaiUjian({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/init-hasil-ujian",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async kirimJawabanSoal({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/input/hasil-ujian",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async selesaiUjian({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/end-ujian",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async subtesPaket({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/subtest-paket`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async publicDetail({ commit }, id) {
      try {
        let config = {
          method: "get",
          url: `/rumpun/${id}`,
          params,
          headers: {
            secret: secretKey,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async indexPembahasan({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/pembahasan-ujian`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async indexRanking({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/ranking-paket`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
};
