import Vue from "vue";

// axios
import axios from "axios";
const baseURL = () =>
  process.env.VUE_APP_API_URL || "https://api.elp-dev.my.id/";
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: baseURL(),
  // timeout: 1000,
  headers: {
    secret: "ADeuCasb4fDkdakK5IJas3CQ5h0TfrWGbiyHbYHBBIblitnj545GHdnrcdgNS6Q",
  },
});

// change header Authorization
axiosIns.interceptors.request.use(
  function (conf) {
    let headers = conf.headers;
    if (headers && (headers.authorization || headers.Authorization)) {
      const authorizationHeader =
        headers.authorization || headers.Authorization;
      headers.author = authorizationHeader;
      conf.headers = headers;
    }
    return conf;
  },
  function (e) {
    return Promise.reject(e);
  }
);

axiosIns.interceptors.response.use(
  function (response) {
    return Promise.resolve(response);
  },
  function (error) {
    if (error.response.status === 401) {
      sessionStorage.clear();
      localStorage.clear();
      router.push({ path: "/login" });
    }
    return Promise.reject(error);
  }
);

Vue.prototype.$http = axiosIns;

export default axiosIns;
